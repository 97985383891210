import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles/main.css";
import { useUsersContext } from "context/usersContext";
import maybeIcon from "assets/images/maybe-icon.png";
import { useHistory } from "react-router-dom";
import Icon from "components/Icon";
import Uploadfile from "../../components/uploadFiles"
import AddPromptModal from "components/AddPromptModal";
import { PromptsContext } from "context/promptsContext";

const Home = () => {
  const { setUserAsUnread, addNewMessage, refresh, showToast, SSO, locValid, agencyInfo, transcript, ChatSubmit } = useUsersContext();
  const history = useHistory();
  const textareaRef = useRef(null);
  const lastMsgRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState('64px');
  const [goBottomBtn, setGoBottomBtn] = useState('120px');
  const [inputHeight, setInputHeight] = useState("64px");
  const [isOpenAddPromptModal, setIsAddPromptModal] = useState(false);
  const { promptsData, getPrompts } = useContext(PromptsContext);
  const [newMessage, setNewMessage] = useState('');

  const addPromptHandle = () => {
    setIsAddPromptModal(true);
  }
  
  const justCloseIt = () => {
    setIsAddPromptModal(false);
  }

  useEffect(() => {
    if (transcript) {
      setNewMessage(transcript);
    }
  }, [transcript]);
  
  const submitNewMessage = async () => {
    const payload = {
      locationId: SSO?.id,
      username: SSO?.chat_username,
    };
 
    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);
      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      addNewMessage(temp.id, newMessage, newMessage, "savetodb");
      history.push(chatUrl);
    } else {
      showToast('Something went wrong!');
    }
    setTimeout(() => {
      document.getElementById('gotovie')?.scrollIntoView({ behavior: 'smooth' });
    }, 2000);
  };

  const detectEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents the default action (new line) when Enter is pressed
      submitNewMessage(newMessage, 'savetodb');
    }
  };

  return (
    <>
      {locValid ? (
        <>
          <div className="chat">
            <div className="chat__body">
              <div className="chat__bg"></div>
              <div className="chat__content">
                <div>
                  <div className="chat__date-wrapper">
                    <span className="chat__date">{SSO?.chat_username}</span>
                  </div>
                  <div className="noMessagePlaceholder">
                    <div className="iconBox">
                      <img src={agencyInfo?.Customization ? agencyInfo?.Customization?.chatapp_logo : maybeIcon} alt="maybe icon" style={{ height: '100px' }} />
                      <p>
                        <b style={{ color: "black" }}>
                          {agencyInfo?.Customization ? agencyInfo?.Customization?.chatapp_text : 'How can Personalised AI help you today?'}
                        </b>
                      </p>
                    </div>
                    <div className="row"></div>
                  </div>
                </div>
              </div>
              <footer className="chat__footer">
                <button
                  style={{ bottom: goBottomBtn }}
                  className="chat__scroll-btn"
                  aria-label="scroll down"
                  id="trigerafterreguest"
                >
                  <Icon id="downArrow" />
                </button>
                <div className="chat__input-wrapper" style={{ height: footerHeight }}>
                  {/* ... other components ... */}
                  <p className="promptonchat" onClick={addPromptHandle}>+ Add Prompt</p>

                  <AddPromptModal
                    isModalOpen={isOpenAddPromptModal}
                    categories={promptsData?.prompt_category?.filter(obj => obj.type != 'super-admin')}
                    subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type != 'super-admin')}
                    justCloseIt={justCloseIt}
                    getUpdatePromptsData={getPrompts}

                  />
                  <div className="pos-rel" style={{ marginBottom: inputHeight }}>
                  </div>

                  <textarea
                    ref={textareaRef}
                    className="chat__input"
                    placeholder="What can your AI help you with next?"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={detectEnterPress}
                    style={{ overflowY: 'auto', position: 'absolute', bottom: "-2px", width: "100%", left: 0, paddingLeft: "50px" }}
                  />
                  <button aria-label="Send message" onClick={() => submitNewMessage(newMessage, 'savetodb')} className="sendChatBtn">
                    <Icon id="sendChat" className="chat__input-icon" />
                  </button>
                  <Uploadfile classname="filecogChatBtn" />
                </div>
                <p className="text-center text-gray">Your data is not used to train our models. AI can make mistakes please check your answer.</p>
              </footer>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>
            To use Maybe* AI please connect us with your social media accounts.
          </h3>
          <button className="form-btn">
            {" "}
            Continue with Facebook{" "}
          </button>
        </>
      )}
    </>
  );
};

export default Home;
