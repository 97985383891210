import React, { useEffect, useState } from "react";
import Icon from "components/Icon";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./index.css";
import env from "config";
import { useUsersContext } from "context/usersContext";
import AnimatedText from "components/Animation/AnimatedText";
import LoadingDots from "components/Animation/LoadingDots";
import AddCategoryModal from "components/AddCategoryModal";
import AddSubCategoryModal from "components/AddSubCategoryModal";
const BASE_URL = `${env.API_URL}/v1`;
const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center" style={{ maxWidth: '660px' }}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

// Example usage in another component
const AddPromptModal = ({
  isModalOpen,
  justCloseIt,
  categories,
  subCategories: initialSubCategories,
  getUpdatePromptsData,
}) => {
  const { SSO } = useUsersContext();

  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showAddSubCategoryModal, setShowAddSubCategoryModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();
  const [subCategories, setSubCategories] = useState(initialSubCategories);

  // prompt payload states
  const [promptName, setPromptName] = useState();
  const [promptCategory, setPromptCategory] = useState('');
  const [promptSubCategory, setPromptSubCategory] = useState('');
  const [promptContent, setPromptContent] = useState();
  const [promptSetAs, setPromptSetAs] = useState("personal");
  const [isAdvanced, setIsAdvanced] = useState(false);

  // error status
  const [promptNameError, setPromptNameError] = useState(null);
  const [promptCategoryError, setPromptCategoryError] = useState(null);
  const [promptSubCategoryError, setPromptSubCategoryError] = useState(null);
  const [promptContentError, setPromptContentError] = useState(null);
  const [promptstep, setpromptstep] = useState(0);
  const [promptQuery, setPromptQuery] = useState('');
  const [promptQueryError, setPromptQueryError] = useState('');
  const resetPromptForm = () => {
    setPromptName("");
    setPromptCategory("");
    setPromptSubCategory("");
    setPromptContent("");
    setPromptSetAs("personal");
  };

  // need to generate conversation_id
  const GeneratePrompt = async () => {
    setpromptstep(1);
    await axios
      .post(BASE_URL + "/prompts/generate", {
        locationId: SSO?.id,
        username: SSO?.chat_username,
        promptQuery: promptQuery
      })
      .then(async function (response) {
        setpromptstep(2);
        setPromptName(response.data?.data?.title);
        setPromptContent(response.data?.data?.content);
      })
      .catch(function (error) {
        setpromptstep(5);
        return error;
      });
  }
  useEffect(() => {
    setPromptQuery("")
    setpromptstep(0);
  }, []);
  useEffect(() => {
    handleCategoryChange(promptCategory);
    // setpromptstep(0);
  }, [initialSubCategories]);
  const handleClose = () => {
    justCloseIt();
    setpromptstep(0);
  };

  const handleCloseModal = () => {
    setShowAddCategoryModal(false);
    setShowAddSubCategoryModal(false);
    getUpdatePromptsData();
  };

  const handleAddCategory = () => {
    setShowAddCategoryModal(true);
  };

  const handleAddSubCategory = () => {
    setShowAddSubCategoryModal(true);
  };

  const handleCategoryChange = (selectedCategoryId) => {
    const filteredSubCategories = initialSubCategories?.filter(
      (subCategory) => subCategory.category_id === selectedCategoryId
    );

    // Set the filtered subcategories and reset the selected subcategory
    setSubCategories(filteredSubCategories);
    if (filteredSubCategories?.length > 0) {
      setPromptSubCategory(filteredSubCategories[0]._id);
    } else {
      setPromptSubCategory("");
    }
  };

  const handlePromptSubmit = async () => {
    setProcessing(true);

    // Reset all previous errors
    setPromptNameError(null);
    setPromptCategoryError(null);
    setPromptSubCategoryError(null);
    setPromptContentError(null);

    let hasError = false;

    // Validate the required fields
    if (!promptName) {
      setPromptNameError("Prompt Name is required");
      hasError = true;
    }

    // if (!promptCategory) {
    //   setPromptCategoryError("Category is required");
    //   hasError = true;
    // }

    // if (!promptSubCategory) {
    //   setPromptSubCategoryError("Sub Category is required");
    //   hasError = true;
    // }

    if (!promptContent) {
      setPromptContentError("Prompt Content is required");
      hasError = true;
    }

    if (hasError) {
      setProcessing(false);
      return;
    }

    const response = await axios.post(`${BASE_URL}/prompts`, {
      title: promptName,
      category: promptCategory,
      sub_category: promptSubCategory,
      prompt_value: promptContent,
      agency_id: SSO?.agency_id,
      type: "agency",
      userId: { id: SSO?.userUUID, type: "author", username: SSO?.chat_username },
      locationId: SSO?.id,
      set_as: promptSetAs,
    });

    setProcessing(false);
    if (response) {
      handleClose();
      getUpdatePromptsData();
      resetPromptForm();
    }
  };
  const handlestep1 = () => {
    if (promptQuery === "") {
      setPromptQueryError(true);
      return false;
    }
    setPromptQueryError(false);
    GeneratePrompt();
  }
  const enableAdvancedSettings = () => {
    setIsAdvanced(prevState => !prevState);
  }
  const handlestep2 = () => {
    setpromptstep(3);
  }
  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <div className="scrollable-modal-content">
          {showAddCategoryModal && (
            <AddCategoryModal onClose={handleCloseModal} />
          )}
          {showAddSubCategoryModal && (
            <AddSubCategoryModal
              onClose={handleCloseModal}
              categories={categories}
            />
          )}

          {!showAddCategoryModal && !showAddSubCategoryModal ? (
            <div style={{ position: "relative" }}>
              {promptstep !== 1 ?
                <>
                  <p
                    style={{
                      position: "absolute",
                      right: 0,
                      width: "12px",
                      height: "12px",
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  >
                    <Icon id="close" />
                  </p>
                </> : ''
              }

              <div className="formGroup">
                {promptstep === 0 && <>
                  <p className="modalText">Add New Prompt</p>
                  <p className="roundofaddprompt z-w-60"><Icon id={'magicstar'} /> <AnimatedText text="What do you want to do?" speed={50} /></p>
                  <div style={{ position: "relative" }}>
                    <input type="text" required placeholder="Start Typing ..." name="promptQuery" onChange={(e) => setPromptQuery(e.target.value)} className="input_od_addPrompt" />
                    <button onClick={handlestep1} style={{ position: "absolute", right: "10px", top: "15px" }}>
                      <Icon id="sendChat" className="chat__input-icon" />
                    </button>
                  </div>
                  {promptQueryError && (
                    <small style={{ color: "red" }}>Should not be empty</small>
                  )}
                </>}
                {promptstep === 1 && <>
                  <p className="roundofaddprompt z-w-100 text-center" style={{ marginBottom: 0 }}><Icon id={'magicstar'} /> Creating new prompt<LoadingDots speed={100} /></p>
                </>}
                {promptstep === 5 && <>
                  <p className="roundofaddprompt z-w-100 text-center" style={{ marginBottom: 0, background: '#ffdddd' }}><Icon id={'magicstar'} /> Something Went Wrong! </p>
                </>}
                {promptstep === 2 && <>
                  <p className="modalText">Add New Prompt</p>
                  <p className="roundofaddprompt z-w-100 "><Icon id={'magicstar'} /> Here’s your prompt, feel free to edit it using the the pencilblack tool...</p>
                  <div style={{ position: "relative", marginBottom: '20px' }}>
                    <label>Prompt Title</label>
                    <input
                      type="text"
                      name="promptName"
                      placeholder="prompt Title"
                      className="flex-1 modalInputs"
                      value={promptName}
                      onChange={(e) => {
                        setPromptName(e.target.value);
                        setPromptNameError(null);
                      }}
                    />
                    <button style={{ position: "absolute", right: "10px", top: "40px" }}>
                      <Icon id="pencilblack" />
                    </button>
                    {promptNameError && (
                      <small style={{ color: "red" }}>{promptNameError}</small>
                    )}
                  </div>
                  <div style={{ position: "relative", marginBottom: '20px' }}>
                    <label>Prompt Content</label>
                    <textarea
                      className="flex-1 modalInputs"
                      style={{ width: "100%", minHeight: "150px" }}
                      value={promptContent}
                      onChange={(e) => {
                        setPromptContent(e.target.value);
                        setPromptContentError(null);
                      }}
                    ></textarea>
                    <button style={{ position: "absolute", right: "10px", top: "40px" }}>
                      <Icon id="pencilblack" />
                    </button>
                    {promptContentError && (
                      <small style={{ color: "red" }}>{promptContentError}</small>
                    )}
                  </div>
                  <div className="formGroup" style={{ marginBottom: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => enableAdvancedSettings()}
                      >
                        Advanced Settings
                      </span>
                    </div>
                  </div>
                  {isAdvanced ?
                    <>
                      <div className="formGroup" style={{ marginBottom: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label>Category</label>
                          <span
                            style={{ textDecoration: "underline", cursor: "pointer" }}
                            onClick={() => handleAddCategory()}
                          >
                            + Add Category
                          </span>
                        </div>
                        <select
                          className="flex-1 modalInputs"
                          style={{ width: "100%" }}
                          name="promptCategory"
                          value={promptCategory}
                          onChange={(e) => {
                            setPromptCategory(e.target.value);
                            handleCategoryChange(e.target.value);
                            setPromptCategoryError(null);
                          }}
                        >
                          <option selected disabled>
                            Select Category
                          </option>
                          {categories?.map((item, index) => (
                            <option value={item._id}>{item.name}</option>
                          ))}
                        </select>
                        {promptCategoryError && (
                          <small style={{ color: "red" }}>{promptCategoryError}</small>
                        )}

                      </div>
                      <div className="formGroup" style={{ marginBottom: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <label>Sub Category</label>
                          <span
                            style={{ textDecoration: "underline", cursor: "pointer" }}
                            onClick={() => handleAddSubCategory()}
                          >
                            + Add Sub Category
                          </span>
                        </div>
                        <select
                          className="flex-1 modalInputs"
                          style={{ width: "100%" }}
                          name="promptSubCategory"
                          value={promptSubCategory}
                          onChange={(e) => {
                            setPromptSubCategory(e.target.value);
                            setPromptSubCategoryError(null);
                          }}
                        >
                          <option selected disabled>
                            Select Sub Category
                          </option>
                          {subCategories?.map((item, index) => (
                            <option value={item._id}>{item.name}</option>
                          ))}
                        </select>
                        {promptSubCategoryError && (
                          <small style={{ color: "red" }}>
                            {promptSubCategoryError}
                          </small>
                        )}
                      </div>
                      <div className="formGroup" style={{ marginBottom: "20px" }}>
                        <label>Set as</label>
                        <div class="radio-group">
                          <div class="radio-button left">
                            <input
                              type="radio"
                              id="personal"
                              class="radio-input"
                              name="radio-group"
                              value="personal"
                              checked={promptSetAs === "personal"}
                              onChange={(e) => setPromptSetAs(e.target.value)}
                            />
                            <label for="personal" class="radio-label">
                              Personal
                            </label>
                          </div>
                          <div class="radio-button right">
                            <input
                              type="radio"
                              id="shared"
                              class="radio-input"
                              name="radio-group"
                              value="shared"
                              checked={promptSetAs === "shared"}
                              onChange={(e) => setPromptSetAs(e.target.value)}
                            />
                            <label for="shared" class="radio-label">
                              <span style={{ marginRight: "8px" }}>
                                <Icon id="shared" />
                              </span>
                              Shared
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                    : ''}
                  <p
                    className="disagreeBtn"
                    style={{
                      margin: 0,
                      width: "100%",
                      opacity: processing ? 0.6 : 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    disabled={processing}
                    onClick={handlePromptSubmit}
                  >
                    {processing && <div className="spinner"></div>}
                    Done
                  </p>
                </>}
              </div>
              {promptstep === 3 && <>
                <p className="modalText">Add New Prompt</p>
                <div className="formGroup" style={{ marginBottom: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label>Category</label>
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => handleAddCategory()}
                    >
                      + Add Category
                    </span>
                  </div>
                  <select
                    className="flex-1 modalInputs"
                    style={{ width: "100%" }}
                    name="promptCategory"
                    value={promptCategory}
                    onChange={(e) => {
                      setPromptCategory(e.target.value);
                      handleCategoryChange(e.target.value);
                      setPromptCategoryError(null);
                    }}
                  >
                    <option selected disabled>
                      Select Category
                    </option>
                    {categories?.map((item, index) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </select>
                  {promptCategoryError && (
                    <small style={{ color: "red" }}>{promptCategoryError}</small>
                  )}

                </div>
                <div className="formGroup" style={{ marginBottom: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label>Sub Category</label>
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => handleAddSubCategory()}
                    >
                      + Add Sub Category
                    </span>
                  </div>
                  <select
                    className="flex-1 modalInputs"
                    style={{ width: "100%" }}
                    name="promptSubCategory"
                    value={promptSubCategory}
                    onChange={(e) => {
                      setPromptSubCategory(e.target.value);
                      setPromptSubCategoryError(null);
                    }}
                  >
                    <option selected disabled>
                      Select Sub Category
                    </option>
                    {subCategories?.map((item, index) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </select>
                  {promptSubCategoryError && (
                    <small style={{ color: "red" }}>
                      {promptSubCategoryError}
                    </small>
                  )}
                </div>
                <div className="formGroup" style={{ marginBottom: "20px" }}>
                  <label>Set as</label>
                  <div class="radio-group">
                    <div class="radio-button left">
                      <input
                        type="radio"
                        id="personal"
                        class="radio-input"
                        name="radio-group"
                        value="personal"
                        checked={promptSetAs === "personal"}
                        onChange={(e) => setPromptSetAs(e.target.value)}
                      />
                      <label for="personal" class="radio-label">
                        Personal
                      </label>
                    </div>
                    <div class="radio-button right">
                      <input
                        type="radio"
                        id="shared"
                        class="radio-input"
                        name="radio-group"
                        value="shared"
                        checked={promptSetAs === "shared"}
                        onChange={(e) => setPromptSetAs(e.target.value)}
                      />
                      <label for="shared" class="radio-label">
                        <span style={{ marginRight: "8px" }}>
                          <Icon id="shared" />
                        </span>
                        Shared
                      </label>
                    </div>
                  </div>
                </div>
                <p
                  className="disagreeBtn"
                  style={{
                    margin: 0,
                    width: "100%",
                    opacity: processing ? 0.6 : 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  disabled={processing}
                  onClick={handlePromptSubmit}
                >
                  {processing && <div className="spinner"></div>}
                  Done
                </p>
              </>}
              {/* <div className="formGroup" style={{ marginBottom: "20px" }}>
                <label>Description</label>
                <input
                  type="text"
                  name="promptDescription"
                  placeholder="Prompt Description"
                  className="flex-1 modalInputs"
                  value={promptDescription}
                  onChange={(e) => {
                  }}
                />
                {promptDescriptionError && (
                  <small style={{ color: "red" }}>
                    {promptDescriptionError}
                  </small>
                )}
              </div> */}


            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </div>
  );
};

// const AddCategoryModal = ({ onClose }) => {
//   const { SSO } = useUsersContext();

//   const [processing, setProcessing] = useState(false);
//   const [error, setError] = useState();
//   const [categoryName, setCategoryName] = useState();

//   const handleCategorySubmit = async () => {
//     setProcessing(true);
//     setError(false);

//     if (categoryName === "" || categoryName === undefined) {
//       setError(true);
//       setProcessing(false);
//       return false;
//     }

//     const response = await axios.post(`${BASE_URL}/prompts/category`, {
//       name: categoryName,
//       userId: SSO?.userUUID,
//       locationId: SSO?.id,
//       type: "agency",
//     });

//     setProcessing(false);
//     if (response) {
//       onClose();
//     }
//   };
//   return (
//     <div style={{ position: "relative" }}>
//       <p
//         style={{
//           position: "absolute",
//           right: 0,
//           width: "12px",
//           height: "12px",
//           cursor: "pointer",
//         }}
//         onClick={onClose}
//       >
//         <Icon id="close" />
//       </p>
//       <p className="modalText">Add Category</p>
//       <div className="formGroup" style={{ marginBottom: "20px" }}>
//         <label>Name</label>
//         <input
//           type="text"
//           name="name"
//           placeholder="Category Name"
//           className="flex-1 modalInputs"
//           onChange={(e) => setCategoryName(e.target.value)}
//         />
//         {error && (
//           <small style={{ color: "red" }}>Category name required!</small>
//         )}
//       </div>
//       <button
//         className="disagreeBtn"
//         style={{
//           margin: 0,
//           width: "100%",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           opacity: processing ? 0.6 : 1,
//         }}
//         disabled={processing}
//         onClick={handleCategorySubmit}
//       >
//         {processing && <div className="spinner"></div>}
//         Save
//       </button>
//     </div>
//   );
// };

// const AddSubCategoryModal = ({ onClose, categories }) => {
//   const { SSO } = useUsersContext();

//   const [processing, setProcessing] = useState(false);
//   const [error, setError] = useState();
//   const [subCategoryName, setSubCategoryName] = useState();
//   const [category_id, setcategory_id] = useState();

//   const handleCategorySubmit = async () => {
//     setProcessing(true);
//     setError(false);

//     if (subCategoryName === "" || subCategoryName === undefined) {
//       setError(true);
//       setProcessing(false);
//       return false;
//     }

//     const response = await axios.post(`${BASE_URL}/prompts/subcategory`, {
//       name: subCategoryName,
//       category_id: category_id,
//       userId: SSO?.userUUID,
//       locationId: SSO?.id,
//       type: "agency",
//     });

//     setProcessing(false);
//     if (response) {
//       onClose();
//     }
//   };
//   return (
//     <div style={{ position: "relative" }}>
//       <p
//         style={{
//           position: "absolute",
//           right: 0,
//           width: "12px",
//           height: "12px",
//           cursor: "pointer",
//         }}
//         onClick={onClose}
//       >
//         <Icon id="close" />
//       </p>
//       <p className="modalText">Add Sub Category</p>
//       <div className="formGroup" style={{ marginBottom: "20px" }}>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <label>Select Parent Category</label>
//         </div>
//         <select
//           className="flex-1 modalInputs"
//           style={{ width: "100%" }}
//           name="promptCategory"
//           value={category_id}
//           onChange={(e) => {
//             setcategory_id(e.target.value);
//           }}
//         >
//           <option selected disabled>
//             Select Category
//           </option>
//           {categories?.map((item, index) => (
//             <option value={item._id}>{item.name}</option>
//           ))}
//         </select>
//       </div>
//       <div className="formGroup" style={{ marginBottom: "20px" }}>
//         <label>Name</label>
//         <input
//           type="text"
//           name="name"
//           placeholder="Sub Category Name"
//           className="flex-1 modalInputs"
//           onChange={(e) => setSubCategoryName(e.target.value)}
//         />
//         {error && (
//           <small style={{ color: "red" }}>Sub Category name required!</small>
//         )}
//       </div>
//       <button
//         className="disagreeBtn"
//         style={{
//           margin: 0,
//           width: "100%",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           opacity: processing ? 0.6 : 1,
//         }}
//         disabled={processing}
//         onClick={handleCategorySubmit}
//       >
//         {processing && <div className="spinner"></div>}
//         Save
//       </button>
//     </div>
//   );
// };

export default AddPromptModal;
