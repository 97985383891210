import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./components/Loader";
import Home from "./pages/Home";
import Settings from "./pages/Settings";
import Sidebar from "components/Sidebar";
import Chat from "pages/Chat";
import SsoHandler from "utils/SSOhandler";
import SettingsSidebar from "components/SettingsSidebar";
import Feeds from "./pages/newsFeed/index"
import axios from "axios";
const userPrefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

function App() {
  const { checkSSO } = SsoHandler();
  const [appLoaded, setAppLoaded] = useState(false);
  const [startLoadProgress, setStartLoadProgress] = useState(false);
  // var isSettingsPage = window.location.pathname.includes("/settings");
  const [isSettingsPage, setIsSettingsPage] = useState(false);
  const [agency_data, setagency_data] = useState({});

  const getAgency = async () => {
    const domain = window.location.hostname
    // const domain = "maybetest.fixmyonline.com"
    await axios.get('https://api.example.com/data?domain=' + domain)
      .then(response => {
        setagency_data(response.data)
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    // getAgency()
    // checkSSO()
    if (userPrefersDark) document.body.classList.add("dark-theme");
    stopLoad();
  }, [isSettingsPage]);

  const stopLoad = () => {
    setStartLoadProgress(true);
    setTimeout(() => setAppLoaded(true), 3000);
  };

  if (!appLoaded) return <Loader done={startLoadProgress} agency_data={agency_data} />;

  return (
    <div className="app">
      <p className="app__mobile-message"> Only available on desktop 😊. </p>
      <Router>
        <div className="app-content">
          {isSettingsPage ?
            <SettingsSidebar setIsSettingsPage={setIsSettingsPage} />
            : <Sidebar setIsSettingsPage={setIsSettingsPage} />}
          <Switch>
            <Route
              path="/chat/:id"
              component={Chat}
            />
            {/* <Route path="/settings" component={Settings} /> */}
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/news-feed" component={Feeds} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
