import React, { useContext, useState } from "react";
import "./styles/main.css";
import { useUsersContext } from "context/usersContext";
import ChatModal from "./components/ChatModal";

const SettingsSidebar = ({ setIsSettingsPage }) => {
  const { users: contacts, locValid, isActiveSide, handleDeleteContact, processing, showExploreContent, enableExplore, enableChat, SSO } = useUsersContext();

  const handleConnect = (e) => {
    e.preventDefault();
    let redirectType = 'stand_alone';
    if (SSO?.isGHLUser) {
      redirectType = 'GHL'
    }
    window.location.href = `https://connect.maybetech.com/?profile_id=${SSO?.id}&email=${SSO?.user_email}&username=${SSO?.chat_username}&type=${redirectType}`;
  };

  return (
    <>
      {locValid ? (
        <aside className={`sidebar ${!isActiveSide ? 'dNone' : ''}`}>
          <header className="header">
            <ChatModal processing={processing} enableExplore={enableExplore} enableChat={enableChat} showExploreContent={showExploreContent} setIsSettingsPage={setIsSettingsPage} />
          </header>
          <div className="sidebar__explore-content">
            <div className="sidebarWrapper">
              <div className="prompt_box_sidebar" >
                <div className="prompt_text" style={{ fontWeight: 'bold' }}>
                  Users
                </div>
              </div>
              <div className="prompt_box_sidebar" onClick={(e) => handleConnect(e)}>
                <div className="prompt_text" style={{ fontWeight: 'bold' }}>
                  Connect Facebook
                </div>
              </div>
            </div>
          </div>
        </aside>
      ) : (
        <></>
      )}
    </>
  );
};

export default SettingsSidebar;
